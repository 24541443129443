import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
// import 'lib-flexible'
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import "./assets/css/responsive.css";
import "@/assets/css/table_public.css"; // global css
import { getToken, intervalRefreshToken } from "./utils/auth.js";
import { addDateRange, handleTree, parseTime, resetForm } from "@/utils/ruoyi";
import "echarts";
import VueEcharts from "vue-echarts";
import plugins from "./plugins"; // plugins
// 分页组件
import Pagination from "@/components/Pagination";
import BaiduMap from "vue-baidu-map";
// 导入qiankun.js
import { registerMicroApps, setDefaultMountApp, start } from "qiankun";

// 自定义组件-文本超出隐藏
import OverflowTxt from "./components/overflowTxt.vue";

// 全局组件挂载
Vue.component("Pagination", Pagination);
Vue.component("VueEcharts", VueEcharts);
Vue.component("overflow-txt", OverflowTxt);

Vue.use(BaiduMap, {
    ak: "DHewLYOSvq4s0TFIaFCagCNWhvmHWzLB",
});

const upmsDomain = process.env.VUE_APP_UPMS_DOMAIN;
console.log(
    `%c子应用访问域名：${upmsDomain}`,
    "background:#409eff;color:#ffffff;padding:5px;border-radius:3px;font-size:14px;"
);
// 注册子应用
registerMicroApps([
    {
        name: "vue app", // 子应用名称
        entry: upmsDomain, // 子应用入口
        container: "#sub", // 子应用所在容器
        activeRule: "/vue", // 子应用触发规则（路径）
    },
]);
// 启动默认应用
// setDefaultMountApp('/vue')

// 开启服务
start({
    prefetch: true, // 取消预加载
});

var _versions = store.state.version;
var baseURL = store.getters.baseURL;
Vue.prototype.resetForm = resetForm;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.parseTime = parseTime;
Vue.prototype.handleTree = handleTree;
Vue.prototype.role = "";
Vue.prototype.userInfo = "";
Vue.prototype.$axios = axios;
Vue.prototype.instance = Vue.prototype.$axios.create({
    baseURL,
});
//请求拦截器
Vue.prototype.instance.interceptors.request.use(
    (config) => {
        config.headers.Authorization = "Bearer " + getToken();
        config.headers["X-From-Business-Line"] = "operate";
        config.headers["X-From-Tenant"] = "9527";
        localStorage.setItem("X-From-Business-Line", "operate");
        localStorage.setItem("X-From-Tenant", "9527");
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
//响应拦截器
var index = 1;
Vue.prototype.instance.interceptors.response.use(
    (res) => {
        if (res.data.code === 401) {
            localStorage.clear();
            index === 1 &&
                window.alert("抱歉，当前账号登录信息已过期，请重新登录");
            index++;
            router.replace({
                path: "/",
            });
            // router.replace({
            //     path: "/",
            //     query: { redirect: router.currentRoute.fullPath }, //登录成功后跳入浏览的当前页面
            // });
        }
        return res;
    },
    (error) => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    localStorage.clear();
                    index == 1 &&
                        window.alert(
                            "抱歉，当前账号登录信息已过期，请重新登录"
                        );
                    index++;
                    router.push({
                        path: "/",
                    });
                // router.replace({
                //     path: "/",
                //     query: { redirect: router.currentRoute.fullPath }, //登录成功后跳入浏览的当前页面
                // });
                // setTimeout(() => {
                //     location.reload();
                // }, 500);
            }
        }
    }
);
Vue.config.productionTip = false;

//定时刷新token
intervalRefreshToken();
//路由跳转前做判断
router.beforeEach((to, from, next) => {
    let AUTH_TOKEN = getToken(); //从sessionStorage中获取是否已登陆过的信息
    // if(to.meta.title){
    //   document.title = to.meta.title
    // }
    if (AUTH_TOKEN) {
        next();
    } else {
        // console.log(to);
        if (to.path === "/") {
            next();
            // next(`/Login?redirect=${to.fullPath}`)
        } else {
            // next(`/Login?redirect=${to.fullPath}`)
            next({
                replace: true,
                name: "Login",
            });
        }
    }
});

Vue.use(ElementUI, { size: "small", zIndex: 3000 });
Vue.use(plugins);
Vue.use(VueAxios, axios);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");

var fontSize = 0;
var fontRem = 0;
// 获取 URL 参数
Vue.prototype.getQueryString = function (name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var href = window.location.href;
    href = decodeURI(href);
    var r = href.substr(href.indexOf("?") + 1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
};

// 时间格式化
Vue.prototype.dateFormatting = function (date, fmt) {
    var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length == 1
                    ? o[k]
                    : ("00" + o[k]).substr(("" + o[k]).length)
            );
    return fmt;
};
Array.prototype.indexOf = function (val) {
    for (var i = 0; i < this.length; i++) {
        if (this[i] == val) return i;
    }
    return -1;
};
Array.prototype.remove = function (val) {
    var index = this.indexOf(val);
    if (index > -1) {
        this.splice(index, 1);
    }
};
