<template>
    <div class="header">
        <div class="flex header_left">
            <div @click="linkHomePage" class="decoration_box">
                <div class="logo">
                    <img src="../../public/images/logo.png" />
                </div>
            </div>
            <div class="fold_icon">
                <i v-show="!isFold" class="el-icon-s-fold" @click="handleFoldMenus(true)"></i>
                <i v-show="isFold" class="el-icon-s-unfold" @click="handleFoldMenus(false)"></i>
            </div>
        </div>
        <div class="header_right">
            <div class="userinfo">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link"> {{ nickname }}<i class="el-icon-caret-bottom el-icon--right"></i> </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item class="clearfix" @click.native="handleLogOut">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <el-dialog title="修改密码" width="600px" :visible.sync="centerDialogVisible" center>
            <el-form @keyup.enter.native="handleLogin" ref="form" :model="form">
                <el-form-item label="原始密码">
                    <el-input v-model="form.oldPassword" placeholder="请输入原始密码" type="password"></el-input>
                </el-form-item>
                <el-form-item label="新密码">
                    <el-input v-model="form.newPassword" placeholder="请输入新密码" type="password"></el-input>
                </el-form-item>
                <el-form-item label="重复新密码">
                    <el-input v-model="form.newPassword2" placeholder="请重复输入新密码" type="password"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleEditPassword">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'MyHeader',
    props: {
        msg: String,
        checkedMenu: Object
    },
    data() {
        return {
            form: {
                oldPassword: '',
                newPassword: '',
                newPassword2: ''
            },
            isFold: true,
            centerDialogVisible: false,
            username: '',
            nickname: '',
            menus: []
        };
    },
    methods: {
        //操作修改密码
        handleEditPassword() {
            if (this.form.newPassword != this.form.newPassword2) {
                this.$message.error('抱歉，您两次输入的新密码不一致');
                return false;
            }
            var json = {
                oldPassword: this.form.oldPassword,
                newPassword: this.form.newPassword,
                username: this.username
            };
            this.instance
                .get('/login/changePassword', {
                    params: json
                })
                .then((res) => {
                    var data = res.data;
                    // this.$message(data.message)
                    if (data.code == 1) {
                        this.centerDialogVisible = false;
                        this.$confirm('密码修改成功，请重新登录。', '提示', {
                            confirmButtonText: '确定',
                            showCancelButton: false,
                            // cancelButtonText: '取消',
                            type: 'warning'
                        })
                            .then(() => {
                                this.$router.replace({
                                    path: '/'
                                });
                            })
                            .catch(() => {
                                this.$router.replace({
                                    path: '/'
                                });
                            });
                        // this.$router.replace({path:'/'});
                    } else {
                        this.$message.error(data.message);
                    }
                });
        },
        //操作退出
        handleLogOut() {
            this.$confirm('此操作将退出当前登录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    localStorage.clear();
                    this.$router.replace({
                        path: '/'
                    });
                    // setTimeout(() => {
                    //     location.reload();
                    // }, 300);

                    // this.$message({
                    //     type: 'success',
                    //     message: '退出成功!'
                    // });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
        },
        //点击logo时的跳转
        linkHomePage() {
            let role = JSON.parse(localStorage.getItem('userInfo')).role;
            if (role == 4) {
                this.$router.push({
                    path: '/bigScreen/index'
                });
            }
        },
        //点击面包屑，是否展开菜单
        handleFoldMenus(isFold) {
            this.isFold = isFold;
            this.$emit('handleFoldMenus', isFold);
        }
    },
    mounted() {
        this.username = localStorage.getItem('username') || '匿名用户';
        this.nickname = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).nickName : '';
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
/deep/.el-dialog--center .el-dialog__body {
    padding: 25px 80px 30px;
}
/deep/ .el-dropdown {
    font-size: 16px;
}
.header {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #262d3c;
    color: #ffffff;

    .userinfo {
        display: flex;
        align-items: center;
        .el-dropdown-link {
            font-family: cursive;
        }
    }
}

.logo {
    width: 90px;
}
.el-icon-s-unfold,
.el-icon-s-fold {
    font-size: 20px;
    cursor: pointer;
}
.decoration_box {
    display: flex;
    align-items: center;
    /* width:4.2rem ; */
}
.decoration_title {
    margin-left: 0.2rem;
    font-size: 0.28rem;
    font-family: Source Han Sans CN;
    font-weight: 800;
    color: #8bd2eb;
    background: linear-gradient(0deg, #80cee3 0%, #e3f9ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.decoration_title .title_left {
    font-size: 0.16rem;
    width: 0.8rem;
    text-align: center;
}
.decoration_title p {
    line-height: 1.2;
}
.header_left {
    width: 220px;
    justify-content: space-between;
    padding-left: 30px;
    display: flex;
}
.header_right {
    min-width: 4rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px;
}
.header_right .el-dropdown {
    color: #fff;
    margin-left: 0.3rem;
    cursor: pointer;
}
.logo {
    width: 120px;
}
.decoration {
    width: 0.8rem;
    margin-right: 0.24rem;
}
.menus {
    font-size: 0.2rem;
}
.menus li {
    padding: 0 0.3rem;
}
.menus li,
.decoration_box,
.contact {
    cursor: pointer;
}
.menus li:hover {
    color: #30fbfe;
    font-weight: 500;
}
.menus .active {
    color: #30fbfe;
    font-weight: 500;
}
</style>
