import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        version: "manager",
    },
    getters: {
        baseTitle(state) {
            var version = state.version;
            return "Sigreal运营平台";
        },
        baseURL(state) {
            let href = "//" + location.hostname + "/manager";
            if (location.hostname === "localhost") {
                href = "//operate-platform-test.sigreal.com.cn/manager";
                // href = '//operate-platform.sigreal.com.cn/manager'
            }
            return href;
        },
    },
    mutations: {},
    actions: {},
    modules: {},
});
