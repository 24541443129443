<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
    </div>
</template>
<script>
import { handleMenuData } from './router/index.js';
export default {
    data() {
        return {};
    },
    mounted() {
        let activeTime;
        if (localStorage.getItem('AUTH_TOKEN')) {
            this.userMenuTree();
        }
    },
    methods: {
        userMenuTree() {
            this.instance
                .get('/upms/api/menu/getMenuTree', { params: {} })
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        localStorage.setItem('userMenu', JSON.stringify(data.data));
                        handleMenuData(data.data);
                    }
                })
                .catch((res) => {
                    console.log(res);
                });
        }
    }
};
</script>

<style>
#app {
    font-family: 'Helvetica Neue', Helvetica, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 微软雅黑, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    font-size: 0.22rem;
    font-weight: 400;
    color: #051932;
    line-height: 1.4;
}
html,
body,
#app {
    height: 100%;
}
.null_data_chart {
    text-align: center;
    padding-top: 0.8rem;
}
.box_border {
    border: 2px solid #006ec4;
    border-radius: 0.1rem;
}
.box_title {
    font-size: 0.18rem;
    text-align: left;
}

.radio_group .el-radio-button--mini .el-radio-button__inner {
    padding: 5px 15px;
}
.box_title_radio {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.home_title {
    font-size: 0.26rem;
    font-weight: 500;
    color: #62f9ff;
    text-align: center;
    margin-bottom: 0.2rem;
    /* height:28px; */
}
/*!*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*!*/
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #fff;
}
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
    background-color: #fff;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
    background-color: #324157;
}

/* 公共样式 */
* {
    scrollbar-track-color: #fff; /*立体滚动条背景颜色*/
    scrollbar-base-color: #324157; /*滚动条的基色*/
    scrollbar-arrow-color: #324157;
}
.txtLink {
    cursor: pointer;
    color: #409eff;
}
.el-pagination {
    text-align: center;
}
</style>
